import {
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { UIDeveloperError, handleError } from '@anchorage/sentry';

import { DATE_UNITS } from './types';

import convertDateToNanos from './convertDateToNanos';

/**
 * TODO: Add explanation
 * TODO: Add back handleError
 * TODO: Make sure DATE_UNITS above ^ are not used anywhere else
 * @param unit
 */
export default function getNanosAtStartOf(unit: DATE_UNITS): string {
  let startFn;
  switch (unit) {
    case DATE_UNITS.TODAY:
      startFn = startOfDay;
      break;
    case DATE_UNITS.WEEK:
      startFn = startOfWeek;
      break;
    case DATE_UNITS.MONTH:
      startFn = startOfMonth;
      break;
    case DATE_UNITS.QUARTER:
      startFn = startOfQuarter;
      break;
    case DATE_UNITS.YEAR:
      startFn = startOfYear;
      break;
    default:
      break;
  }

  if (!startFn) {
    handleError(
      new UIDeveloperError(
        `getNanosAtStartOf called with invalid unit ${unit}`,
      ),
    );
    return '';
  }

  return convertDateToNanos(startFn(Date.now()));
}
