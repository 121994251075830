import { UIDeveloperError, handleError } from '@anchorage/sentry';

export default function convertEpochToDate(timestamp: number | string): Date {
  const timestampAsNumber = Number(timestamp);
  if (Number.isNaN(timestampAsNumber)) {
    handleError(
      new UIDeveloperError(
        `Unsupported timestamp format in convertEpochToDate: ${timestamp.toString()}`,
      ),
    );
  }

  const date = new Date(0);
  date.setUTCSeconds(timestampAsNumber);
  return date;
}
